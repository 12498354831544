@import "./variables";

html {
  scroll-behavior: smooth;
}

body {
  font-family: $fontPrimary;
  color: white;
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;
  background-image: radial-gradient(
      49% 81% at 45% 47%,
      #9d500445 0%,
      #073aff00 100%
    ),
    radial-gradient(113% 91% at 17% -2%, #c95011ff 1%, #dc4e4e00 99%),
    radial-gradient(142% 91% at 83% 7%, #edd509ff 1%, #ff000000 99%),
    radial-gradient(142% 91% at -6% 74%, #a30633ff 1%, #ff000000 99%),
    radial-gradient(142% 91% at 111% 84%, #ef710eff 0%, #ed0707ff 100%);
  scroll-behavior: smooth;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

h1,
h2,
h3 {
  font-family: Roboto;
}

h1 {
  font-size: 2.4em;
  margin-bottom: 1rem;

  @media screen and (min-width: 1000px) {
    font-size: 3.1em;
  }
}

.stylized-paragraph {
  font-size: 1.15em;
  line-height: 1.2em;
  text-align: center;
  margin-bottom: 1.5rem;
}

.stylized-paragraph-wide {
  margin-bottom: 2rem;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.App {
  width: 100%;
  min-height: 100vh;
}

.quiz-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 1rem 1rem 1rem;

  @media screen and (min-width: 440px) {
    padding: 2rem 3rem;
  }

  @media screen and (min-width: 540px) {
    padding: 1rem 0;

    .button-wrapper,
    .s-outer-wrapper {
      max-width: 25rem;
    }

    .stylized-paragraph {
      max-width: 35rem;
    }

    .stylized-paragraph-wide {
      max-width: 40rem;
    }
  }
}

.quiz-active {
  h1 {
    font-size: 1.4em;
    margin-bottom: 2rem;
    font-family: "Consolas", "Roboto Mono", "Source Code Pro", Arial, monospace;

    @media screen and (min-width: 600px) {
      font-size: 1.6em;
    }

    @media screen and (min-width: 760px) {
      font-size: 2em;
    }

    @media screen and (min-width: 1000px) {
      font-size: 3.7em;
    }

    @media screen and (min-width: 1200px) {
      font-size: 5em;
    }
  }

  button {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    font-family: "Consolas", "Roboto Mono", "Source Code Pro", Arial, monospace;
    background: transparent;
    border: 1px solid white;
    color: white;
    padding: 1rem 1rem;
    width: 100%;
    transition: 0.2s;

    @media screen and (min-width: 1000px) {
      &:hover {
        background: white;
        color: black;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 999px) {
      &:active {
        background: white;
        color: black;
        cursor: pointer;
      }
    }
  }

  &.tab-mode {
    button {
      &:focus {
        background: white;
        color: black;
        cursor: pointer;
      }
    }
  }
}

.button-wrapper {
  .text-transition_inner {
    text-align: center;

    div {
      width: 100%;
      text-align: center;
    }
  }
}

.progress {
  position: fixed;
  display: none;
  bottom: 1rem;
  opacity: 0;

  .margin-right {
    margin-right: 0.3em;
  }
}

.progress-animation-one {
  display: flex;
}

.progress-animation-two {
  transition: all 1s cubic-bezier(1, 0.06, 0.49, -0.2);
  opacity: 1;
}

.skew {
  transition: all 0.5s ease;
  transform: rotate(-10deg);
}

.slide-out {
  transition: all 0.5s ease;
  position: fixed;
  margin-top: 1000px;
}

.slide-in-init {
  position: fixed;
  margin-top: 1000px;
}

.slide-in {
  transition: all 0.5s ease;
  margin-top: 0;
}

.no-overflow {
  overflow: hidden;
}

a,
.link {
  text-decoration: underline;
  color: #fff;

  &:active,
  &:focus {
    color: #b5cfff;
  }

  @media screen and (min-width: 1000px) {
    &:hover {
      color: #99b8f3;
    }
  }
}

.link {
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  @media screen and (min-width: 1000px) {
    &:focus {
      color: #fff;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.inline-code {
  font-family: "Consolas", "Roboto Mono", "Source Code Pro", Arial, monospace;
  // background-color: #f1f1f1;
  // padding: 0 0.5rem;
}

code {
  font-family: "Consolas", "Roboto Mono", "Source Code Pro", Arial, monospace;
  color: #eaeff4;
  background-color: #1b2129;
  padding: 1rem;
  border-radius: 6px;
}

.results-wrapper {
  padding: 3rem 0.7rem;
  // background-color: rgba(0,0,0,0.2);
  margin-top: 1000px;

  &.show {
    transition: all 0.5s ease;
    margin-top: 0;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 3em;
    padding: 1rem;
    text-align: center;

    @media screen and (min-width: 1068px) {
      margin-bottom: 5rem;
      margin-top: 2rem;
      font-size: 5em;
    }
  }

  @media screen and (min-width: 1000px) {
    padding: 6rem 0.7rem;
  }

  .results-list {
    list-style: none;
    padding-left: 0;
    width: 100%;
    max-width: 60rem;

    .result-item {
      display: flex;
      flex-direction: column;
      // background: rgba(0,0,0,0.4);
      background: #070f19;
      margin-bottom: 2rem;
      border-radius: 4px;

      .result-item-inner-wrapper {
        display: flex;
        flex-direction: column;
        padding: 1rem;
      }

      .result-question,
      .result-correct-answer span,
      .result-user-answer span {
        font-family: "Consolas", "Roboto Mono", "Source Code Pro", Arial,
          monospace;
      }

      .result-user-answer {
        margin-top: 0.1rem;
      }

      .result-question {
        font-size: 1.3em;
        padding: 1rem;
        background-color: #0f294a;
        border-radius: 4px 4px 0 0;

        @media screen and (min-width: 800px) {
          font-size: 1.5em;
          // margin-bottom: 0.4rem;
        }

        @media screen and (min-width: 1000px) {
          font-size: 2em;
          // margin-bottom: 0.6rem;
        }
      }

      p {
        margin-top: 1rem;
      }

      code {
        margin-top: 1rem;
      }
    }
  }
}

.inline-answer {
  padding: 0.5rem 1rem;
  margin-top: 1rem;

  &.answered-correctly {
    background: #005300;
  }

  &.answered-incorrectly {
    background: #970707;
  }

  &.answered-truthfully {
    background: #d29001;
  }

  @media screen and (min-width: 740px) {
    // display: none;
    padding: 0.5rem 1rem;
    max-width: 15rem;
  }
}

.row-answer-wrapper {
  display: none;

  @media screen and (min-width: 740px) {
    display: block;
  }
}

.result-item-inner-wrapper-top-row {
  display: flex;
  justify-content: space-between;
}

.margin-top-2 {
  margin-top: 2rem;
}

.s-outer-wrapper {
  margin-top: 3rem;
  width: 100%;
  padding: 1rem 2rem;
  text-align: center;
  border-radius: 4px;

  img {
    max-height: 2rem;
    width: auto;
    transition: 0.5s;

    @media screen and (min-width: 1000px) {
      max-height: 3rem;
    }
  }

  .s-wrapper {
    display: flex;
    flex-direction: column;
  }

  .s-inner-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  a {
    display: flex;
    flex-direction: column;
    margin-right: 1.5rem;
    margin-top: auto;
    margin-bottom: auto;

    &:last-of-type {
      margin-right: 0rem;

      img {
        width: 6rem;
      }
    }

    @media screen and (min-width: 1000px) {
      margin-right: 2rem;

      &:last-of-type {
        margin-right: 0rem;

        img {
          width: 8rem;
        }
      }

      &:hover {
        cursor: pointer;

        img {
          -webkit-transform: rotate(-12deg);
          transform: rotate(-12deg);
        }
      }
    }
  }

  .s-header {
    letter-spacing: 0.3em;
    font-weight: 300;
    font-size: 0.7em;
    margin-bottom: 1rem;
  }

  .s-logo {
    font-weight: 700;
    margin-top: 1rem;
    font-size: 2em;
    font-style: italic;
  }

  @media screen and (min-width: 800px) and (min-height: 650px) {
    position: absolute;
    bottom: 1rem;
    border: 0;
  }
}

.modal {
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: radial-gradient(49% 81% at 45% 47%, #9d500445 0%, #073aff00 100%),
    radial-gradient(113% 91% at 17% -2%, #c95011ff 1%, #dc4e4e00 99%),
    radial-gradient(142% 91% at 83% 7%, #edd509ff 1%, #ff000000 99%),
    radial-gradient(142% 91% at -6% 74%, #a30633ff 1%, #ff000000 99%),
    radial-gradient(142% 91% at 111% 84%, #ef710eff 0%, #ed0707ff 100%);
  padding: 1rem;

  @media screen and (min-width: 350px) {
    padding: 1.5rem;
  }

  @media screen and (min-width: 450px) {
    padding: 2rem;
  }

  @media screen and (min-width: 550px) {
    padding: 3rem;
  }

  @media screen and (min-width: 750px) {
    background: rgba(0, 0, 0, 0.65);
  }

  .modal-wrapper {
    background: #070f19;
    width: 100%;
    min-height: 100%;
    padding: 1rem;
    border-radius: 6px;

    p {
      margin-bottom: 0.7rem;

      &:last-of-type {
        margin-bottom: 1rem;
      }
    }

    @media screen and (min-width: 750px) {
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
      padding: 2rem;

      p {
        margin-bottom: 1rem;

        &:last-of-type {
          margin-bottom: 1.3rem;
        }
      }
    }
  }
}
