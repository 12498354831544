$fontPrimary: Roboto;
$fontSiteHeader: Lobster, Roboto;
$marginStandard: 1rem;
$clrPrimary: #FFB30B;
$clrDark: #1d1d1d;
$clrDarkHover: #313131;
$clrPositive: #2447FF;
$clrPositiveHover: #415FFF;
$clrNegative: #ff0808;
$clrPositiveLight: white;
$clrPositiveLightHover: #bdc7ff;